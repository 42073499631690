import IntroJs from 'intro.js'

const intro = IntroJs()
intro.setOptions({
  nextLabel: '下一步', // 下一步按钮文字
  prevLabel: '上一步', // 上一步按钮文字
  doneLabel: '我知道了', // 完成按钮文字
  skipLabel: 'X', // 跳过按钮文字
  tooltipClass: 'tooltipClass',
  highlightClass: 'highlightClass',
  hidePrev: true, // 在第一步中是否隐藏上一步按钮
  hideNext: false, // 在最后一步中是否隐藏下一步按钮
  exitOnOverlayClick: false, // 点击叠加层时是否退出介绍
  showStepNumbers: false, // 是否显示红色圆圈的步骤编号
  disableInteraction: true, // 是否禁用与突出显示的框内的元素的交互，就是禁止点击
  showBullets: false // 是否显示面板指示点
})

export default intro
